<template>
  <div class="container-fluid text-left">
    <div class="card nw-card">
      <div class="card-header nw-card-header">
        <h4>Patient Survey Responses</h4>
      </div>
      <div class="card-body" >
        <div class="row">
          <div class="col-6 border-right">

            <div class="row mt-2">
              <div class="col-6 text-left">
                <button class="btn btn-outline-secondary"><i class="fa fa-chevron-left"></i></button>
              </div>
              <div class="col-6 text-right">
                {{ skip }}
                <button class="btn btn-outline-secondary"><i class="fa fa-chevron-right"></i></button>
              </div>
            </div>

            <table class="table table-hover table-sm mt-2">
              <thead><tr><th>Time</th><th>Survey</th></tr></thead>
              <tbody style="cursor:pointer">
                <tr v-for="(item,idx) in arr" @click="select(idx)">
                  <td>{{ item.surveyDate}}</td>
                  <td>{{ item.title}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6" v-show="selected.oid > 0">
            <div class="text-center h3">{{ selected.title}}</div>
            <table class="table">
              <thead><tr><th>Question</th><th>Answer</th></tr></thead>
              <tbody>
                <tr v-for="(item,idx) in selected.questions">
                  <td>{{ item.question}}</td>
                  <td class="text-primary">{{ item.answer}}</td>
                </tr>
              </tbody>
            </table>



            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'

export default {
  data () {
    return {
      nc: NetClient,
      arr: [
        {
          oid: 123,
          title: 'Patient Data Release Form',
          surveyDate: '2021-01-01 09:24:00 AM',
          questions : [
            {
              question: 'Allow Access?',
              answer: 'Approve - I want my physician to see my sleep data'
            }
          ]
        },
        {
          oid: 234,
          title: 'User Experience Survey',
          surveyDate: '2021-10-15 08:15:00 AM',
          questions : [
            {
              question: 'Are you Better?',
              answer: 'Better than before.'
            },
            {
              question: 'On a scale of 1-10 would you recoomend NightWare to others?',
              answer: '8'
            },
            {
              question: 'What could we do better?',
              answer: 'Make vibration stringer'
            }
          ]
        }
      ],
      selected: { oid: -1, title: '', questions: []},
      skip: 0,
      limit: 10
    }
  },
  methods: {
    select (_idx)
    {
      this.selected = this.arr[_idx]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
